<template>

<div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
    <div class="dashboard-navbar">

        <div class="d-user-avater">
            <base-image
                 :imgName="accountProfilePicture"
                 imgGeo="profile_picture"
                 imgHost="remote"
                 imgAlt="Avatar"
                 imgClass="img-fluid rounded-circle"
                 :imgDim="[256,256]"
                 :key="profileImageKey"
             >
             </base-image>

            <h4>{{ clientName }}</h4>
            <div>  {{ clientRole?'( '+clientRole+' )':''  }} </div>
            <div v-if="!editProfile">
                <router-link to="/profile">
                    View resume
                </router-link>
            </div>
            <div v-if="editProfile" class="tiny-font ">
                <span><i class="bi bi-pencil-square"></i></span>
                <span class="cursor-pointer" @click="updateModalTitle('profile')" data-toggle="modal" data-target="#updateProfile">
                    Edit profile
                </span>
                <span> | </span>
                <span class="cursor-pointer"
                      data-toggle="modal"
                      data-target="#actionAvatarModal"
                      @click="updateUploadModalKey"
                      >

                    Change avatar
                </span>
            </div>
        </div>


    </div>

    <div class="dashboard-navbar mt-3 light-w">

        <div class="d-navigation " v-if=" activeBlock === 'insight' ">
            <h4 class="p-3">Insights</h4>
            <ul id="metismenu">
                <li :class="activeMenu === 'dashboard'?'active':'' ">
                    <router-link to="/dashboard">
                        <i class="bi bi-check-square"></i> Overview
                    </router-link>
                </li>
                <li :class="activeMenu === 'application_history'?'active':'' " >
                    <router-link to="/application_history">
                        <i class="bi bi-check-square"></i> Applications
                        <!-- <span class="float-right text-white bg-primary p-1 px-4 rounded" >5</span> -->
                    </router-link>
                </li>
                <li :class="activeMenu === 'bookmark_history'?'active':'' ">
                    <router-link to="/bookmark_history">
                        <i class="bi bi-star"></i> Bookmarks
                    </router-link>
                </li>
                <li :class="activeMenu === 'analysis'?'active':'' " >
                    <router-link to="/analysis">
                        <i class="bi bi-graph-up"></i> Analysis
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="d-navigation " v-if=" activeBlock === 'profile' ">
            <h4 class="p-3">Profile/CV</h4>
            <ul id="metismenu">
                <li>
                    <a href="#">
                        <i class="bi bi-check-square"></i> Profile
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="bi bi-check-square"></i> Work experience
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="bi bi-check-square"></i> Academic
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="bi bi-check-square"></i> Accomplishments
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="bi bi-check-square"></i> Skills
                    </a>
                </li>
            </ul>
        </div>

        <div class="d-navigation " v-if=" activeBlock === 'settings' ">
            <h4 class="p-3">Account Settings</h4>
            <ul id="metismenu">
                <li :class="activeMenu === 'password'?'active':'' " >
                    <router-link to="/account_settings/password">
                        <i class="bi bi-check-square"></i> Change password
                    </router-link>
                </li>
                <li :class="activeMenu === 'email'?'active':'' ">
                    <router-link to="/account_settings/email">
                        <i class="bi bi-star"></i> Change Email
                    </router-link>
                </li>
                <li :class="activeMenu === 'preferences'?'active':'' ">
                    <router-link to="/account_settings/preferences">
                        <i class="bi bi-kanban"></i> Preferences
                    </router-link>
                </li>
            </ul>
        </div>

    </div>
</div>


<base-modal mId="updateProfile" mSize="" >

     <template #header-left-slot>
                {{ modalTitle }}
     </template>
     <template #default>
         <create-profile-main @emit-update-name-role="updateNameRole">
         </create-profile-main>
     </template>
</base-modal>

<base-modal mId="actionAvatarModal"  mBg="bg-bage"   >
     <template #header-left-slot>
        Edit avatar
     </template>

     <template #default>
         <!-- create admin teams  -->
         <utility-image-upload
             @emit-update-profile-image-key="updateProfileImageKey"
             :key="uploadModalKey"
         >
         </utility-image-upload>

     </template>
</base-modal>


</template>

<script>
import { useStore } from 'vuex';
import { ref,computed } from 'vue';
import CreateProfileMain from '@/components/forms/profile/CreateProfileMain.vue';
import UtilityImageUpload from '@/components/utility/UtilityImageUpload.vue';

export default{
    props:{
        editProfile:{
            type:Boolean,
            default:false
        },
        activeMenu:{
            type:String,
            default:"dashboard",
        },
        activeBlock:{
            type:String,
            default:"insight",
        }
    },
    components:{
        CreateProfileMain,
        UtilityImageUpload
    },
    setup(){

        // get the global vuex store values
        const store = useStore();

        const modalTitle = ref("");

        const modalTitleList = {
            profile:'Update profile'
        }

        function updateModalTitle(action){
            modalTitle.value = modalTitleList[action]
        }


        const clientName = ref(store.getters["auth/getAuthCredentials"]["accountName"]);
        const clientRole = ref(store.getters["auth/getAuthCredentials"]["accountRole"]);

        function updateNameRole(name , role){

            const newCredentials = { accountName: name, accountRole: role }

            store.dispatch("auth/executeSetAuthCredentials", newCredentials);

            clientName.value = name;
            clientRole.value = role;

        }

        const accountProfilePicture = computed(function(){
            return store.getters['auth/getAuthCredentials']['accountPicture'];
        });


        function getRandomKey(){
            const date = new Date();
            return Math.floor(date.getTime() / 1000);
        }

        const profileImageKey = ref("default_key")

        function updateProfileImageKey(){

            profileImageKey.value= getRandomKey()

        }

        const uploadModalKey = ref("default_key")

        function updateUploadModalKey(){

            uploadModalKey.value= getRandomKey()

        }



        return {
            clientName,
            clientRole,
            modalTitle,
            accountProfilePicture,
            updateModalTitle,
            updateNameRole,
            uploadModalKey,
            updateUploadModalKey,
            profileImageKey,
            updateProfileImageKey
        }

    }


}
</script>
