<template>
  <form  @submit.prevent="createUpdateProfile" >

            <div  v-if="existingLocationData">
                    <div class="col-xl-12 col-lg-12 col-12">
                        <div class="form-group">
                            <label :class="fv.name.msg?'text-danger':''" for="email">
                                Full name
                                <span v-if="fv.name.msg" > | {{fv.name.msg}}</span>
                            </label>
                            <input type="text" placeholder="Enter name"
                                   required v-model.trim="fv.name.val"
                                   @keyup="validate.validateFormData(fv,'name',true)"
                                   class="form-control with-light" id="email">
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-12">
                        <div class="form-group">
                            <label :class="fv.gender.msg?'text-danger':''" for="email">
                                Gender
                                <span v-if="fv.gender.msg" > | {{fv.gender.msg}}</span>
                            </label>
                            <select class="form-control with-light" style="height:36px"
                                    v-model.trim="fv.gender.val"
                                    @change="validate.validateFormData(fv,'gender',true)">
                                   <option value="">Select gender</option>
                                   <option v-for="gen in genderList"
                                           :value="gen.value"
                                           :key="gen.value"
                                   >
                                       {{gen.alias}}
                                   </option>
                             </select>
                             <div v-if="fv.gender.val == 'other'">
                                 <label :class="fv.otherGender.msg?'text-danger mt-2':'' " for="other-gender">
                                     <span v-if="fv.otherGender.msg" > {{fv.otherGender.msg}}</span>
                                 </label>
                                 <input type="text" placeholder="Enter your preferred gender title"
                                        required v-model.trim="fv.otherGender.val"
                                        @keyup="validate.validateFormData(fv,'otherGender',true)"
                                        class="form-control with-light mt-0" id="other-gender">
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-12">
                        <div class="form-group">
                            <label :class="fv.primaryRole.msg?'text-danger':''" for="email">
                                Primary role
                                <span v-if="fv.primaryRole.msg" > | {{fv.primaryRole.msg}}</span>
                            </label>
                            <input type="text" placeholder="Enter primary role e.g Software developer"
                                   required v-model.trim="fv.primaryRole.val"
                                   @keyup="validate.validateFormData(fv,'primaryRole',true)"
                                   class="form-control with-light" id="email">
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-12">
                        <div class="form-group">
                            <label :class="fv.username.msg?'text-danger':''" for="password">
                                Username
                                <span v-if="fv.username.msg" > | {{fv.username.msg}}</span>
                            </label>
                            <div class="input-group">
                                <div class="input-group-prepend rounded-left cursor-pointer">
                                    <span class="input-group-text b-0 rounded-right"
                                          id="basic-addon1" >
                                        resume.sabenzi.com/
                                    </span>
                                </div>
                                <input class="form-control with-light" required id="username" type="text"
                                       :class="fv.username.msg?'border-danger':''"
                                       v-model="fv.username.val"
                                       @keyup="validate.validateFormData(fv,'username',true)"
                                       placeholder="Enter username">

                            </div>
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-12">
                        <div class="form-group">
                            <label :class="fv.country.msg?'text-danger':''" for="email">
                                Country
                                <span v-if="fv.country.msg" > | {{fv.country.msg}}</span>
                            </label>
                          <select class="form-control with-light" style="height:36px"
                                  v-model.trim="fv.country.val"
                                  @change="validate.validateFormData(fv,'country',true)">
                                 <option value="">Select country</option>
                                 <option v-for="country in countryList"
                                         :value="country.country_name"
                                         :key="country.id"
                                 >
                                     {{country.country_alias}}
                                 </option>
                           </select>
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-12">
                        <div class="form-group">
                            <label :class="fv.city.msg?'text-danger':''" for="email">
                                City
                                <span v-if="fv.city.msg" > | {{fv.city.msg}}</span>
                            </label>
                          <select class="form-control with-light" style="height:36px"
                                  v-model.trim="fv.city.val"
                                  @change="validate.validateFormData(fv,'city',true)">
                                 <option value="">Select city</option>
                                 <option v-for="city in activeCityList"
                                         :value="city.city_name"
                                         :key="city.id"
                                 >
                                     {{city.city_alias}}
                                 </option>
                           </select>
                        </div>
                    </div>

                    <div class="col-12" v-if="fv.reqFeedback.status">

                            <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-12" v-if="fv.showSubmit.val">
                            <button type="submit" class="btn btn-save col-12">Update account profile</button>
                    </div>
                    <base-spinner class="ml-3" v-else></base-spinner>

            </div>
            <base-card-response class="mt-1" cardType="secondary"  v-else>

                <template #header></template>
                <template #default>
                    <p>
                        {{reqError}}
                    </p>
                </template>

            </base-card-response>
  </form>

</template>


<script>
import { ref,reactive,inject,computed } from 'vue'
import { useStore } from 'vuex';
import validators from '@/hooks/validation'

export default{
  name: 'CreateAuthSignUp',
  emits: ['emitUpdateNameRole'],
  setup(props, { emit }) {


    // get the global vuex store values
    const store = useStore();

    // get the validators
    const validate = validators();

    // get the axios object
    const axios = inject('axios');  // inject axios

    //get the urls
    const coreUrl = inject('coreUrl');

    // use the dispatch function to call an action that calls a mutation
    // to set the data of for the job ad
    const oldAccountData = ref(false);
    oldAccountData.value = store.getters["account/getAccountData"];

    const reqError = ref("Loading forms...");
    const existingLocationData = ref(true);

     // create variables for the values to be submitted
    const fv = reactive({
        name : {status:false, val:oldAccountData.value.full_name, msg:false, validate:["required",
                                                                 "safeSpaceInput",
                                                                 {option:"textMin",val:3},
                                                                 {option:"textMax",val:120}
                                                                ]},
        primaryRole : {status:false, val:oldAccountData.value.primary_role, msg:false, validate:["required",
                                                                 "safeSpaceInput",
                                                                 {option:"textMin",val:3},
                                                                 {option:"textMax",val:140}
                                                                ]},
        gender : {status:false, val:oldAccountData.value.gender?oldAccountData.value.gender:"", msg:false, validate:["required","safeInput"]},
        otherGender : {status:false, val:"", msg:false, validate:["required","safeInput"]},
        username : {status:false, val:oldAccountData.value.username, msg:false, validate:["required","safeSpaceInput"]},
        country : {status:false, val:oldAccountData.value.country?oldAccountData.value.country:"", msg:false, validate:["required"]},
        city : {status:false, val:oldAccountData.value.city?oldAccountData.value.city:"", msg:false, validate:["required"]},
        reqFeedback : {status:false, val:"", msg:false},
        showSubmit : {status:false, val:true}
    })


    const genderList = [
        {value:"male", alias:"Male"},
        {value:"female", alias:"Female"},
        {value:"anonymous", alias:"Prefer not to state"},
        {value:"other", alias:"Other"}
    ]


    //get the data for country and cities
    const countryList = ref(false);
    const cityList = ref(false);

    axios.get(
            coreUrl.backendUrl+'get_location_data',
            {withCredentials: true}).then((response) =>{

            // return the data to result and update existing data variable
            const result = response.data.data;

            countryList.value = result.countryList;
            cityList.value = result.cityList;
            existingLocationData.value = true;

        }).catch((error)=>{
            let errorMsg = "We can't load the form at this time";
            reqError.value = error.response.data.message.req_msg? errorMsg : errorMsg;
            existingLocationData.value = false;
        })

    const activeCityList = computed(function(){
        let newCityList = [];

        if(cityList.value){
            for(let i =0; i < cityList["value"].length; i++){
                if(cityList.value[i]["country_name"] === fv.country.val){
                    newCityList.push(cityList.value[i]);
                }
            }
        }

        return newCityList;

    });


    // create the account using async
    async function createUpdateProfile(){

        // end the action of making a submission, and hide loader
        fv.showSubmit.val = false;

        // load the form data , and append all the necessary fields
        let formData = new FormData();
        formData.append("fv_name", fv.name.val);
        formData.append("fv_gender", fv.gender.val !== 'other' ? fv.gender.val : fv.gender.val.replace(/ /g,"_"));
        formData.append("fv_primaryRole", fv.primaryRole.val);
        formData.append("fv_username", fv.username.val);
        formData.append("fv_country", fv.country.val);
        formData.append("fv_city", fv.city.val);


        // post the data using axios
        try{
            const response = await axios.put(
                coreUrl.backendUrl+'create_individual_main_details',
                formData,
                {
                    withCredentials: true,
                    headers:{
                       "Content-Type": "multipart/form-data"
                    }
                }
            );

            // If successful, get the resppnse data
            const responseData = response.data;

            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = responseData.message.req_msg;
            fv.reqFeedback.val = "success";
            fv.showSubmit.status = false;


            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;

            emit('emitUpdateNameRole', fv.name.val, fv.primaryRole.val)


        }catch(error){

            // load the error to the appropriate field
            const fv_msg = error.response.data.message.fv_msg;
            const req_msg = error.response.data.message.req_msg;

            for(const key in fv_msg){
                if(fv[key]){
                    fv[key]["status"] = false;
                    fv[key]["msg"] = fv_msg[key];
                }
            }


            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = req_msg;
            fv.reqFeedback.val = "danger";

            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;

        }

    }

    return {
        fv,
        validate,
        existingLocationData,
        reqError,
        genderList,
        countryList,
        activeCityList,
        createUpdateProfile
     }
  }
}
</script>
